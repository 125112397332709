import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from 'src/app/_services/profile.service';
import { LoginService } from 'src/app/_services/login.service';
import { NgForm } from '@angular/forms';
import { ProfileForm } from 'src/app/_classes/profile-form';
import { DataService } from 'src/app/_services/data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public profileData = new ProfileForm();
  public _uid: string;
  public isResponse = 0;
  constructor(
    private _profileService: ProfileService,
    private LoginService: LoginService,
    private _DataService: DataService) { }
    @ViewChild(NgForm) profileDataForm: NgForm;  
  ngOnInit() {
    this._uid = this.LoginService.getUid();
    this._profileService.getProfile(this._uid).subscribe((data)=>{
        this.profileData = data.data;
        this.isResponse = 1;   
    })
  }
  onSubmit(){

  }
  updateProfile(){
    if (this.profileDataForm.form.valid){
      this.isResponse = 0;
      this._profileService.updateProfile(this.profileData).subscribe(
        (data)=>{
          //process
          //console.log(data);
          this.isResponse =1;
          let fname = this.profileData.full_name.split(" ");
          this._DataService.changeFirstName(fname[0]);
          this.LoginService.setFirstName(fname[0]);
        });
    }
  }

}
