import { Component, OnInit, ViewChild } from '@angular/core';
import { FeedbackForm } from 'src/app/_classes/feedback-form';
import { Title } from '@angular/platform-browser';
import { FeedbackFormService } from 'src/app/_services/feedback-form.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {

  feedbackFormData = new FeedbackForm();
  submitSuccess= 0;
  isResponse = 1;
  constructor(
      private feedbackFormService: FeedbackFormService) { }
  
  @ViewChild(NgForm) feedbackForm: NgForm;
  onSubmit(){
    this.isResponse = 0;
    this.feedbackFormService.createFeedback(this.feedbackFormData).subscribe(
      (data) =>{
        if (data.status == "1"){
        this.submitSuccess = 1;
        this.feedbackForm.resetForm();
        }else{
          this.submitSuccess = 0;
        }
        this.isResponse = 1;
      } 
    )
  }
  resetFlags(){
    this.submitSuccess = 0;
  }
  ngOnInit() {
    
  }

}
