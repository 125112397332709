export class OrderCustomLabTest {
    public uid: string;
    public addressId: string;
    public patientAge: string;
    public dateTimeSlot: string;
    public paymentMode: string;
    public patientName: string;
    public testId: string;
    public testIds: string;
    public price: number;
    public message: string;
    constructor(){}
}
