import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import AppConstants from '../config/AppConstants';
import { MedicalEquipment } from '../_interface/medical-equipment';

@Injectable({
  providedIn: 'root'
})
export class MedicalEquipmentsService {

  constructor(private _http: HttpClient) { }
  getData(){
    return this._http.get<MedicalEquipment>(AppConstants._GET_MEDICAL_EQUIPMENT_URL_);
  }
  getById(id: any){
    return this._http.get<MedicalEquipment>(AppConstants._GET_MEDICAL_EQUIPMENT_URL_+"&id="+id);
  }
}
