export class OrderLabTest {
    public uid: string;
    public addressId: string;
    public patientAge: string;
    public dateTimeSlot: string;
    public paymentMode: string;
    public patientName: string;
    public packageId: string;
    public price: string;
    public message: string;
    constructor(){}
}
