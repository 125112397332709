import { Component, OnInit } from '@angular/core';
import AppConstants from '../config/AppConstants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public phoneNumber;
	public emailAddress;
  constructor() { }

  ngOnInit() {
    this.phoneNumber = AppConstants._COMPANY_PHONE_NUMBER_;
		this.emailAddress = AppConstants._COMPANY_EMAIL_ADDRESS_;
  }

}
