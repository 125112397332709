import { Component, OnInit, ViewChild } from '@angular/core';
import { Login } from 'src/app/_classes/login';
import { LoginService } from 'src/app/_services/login.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/_services/data.service';
import { NgForm } from '@angular/forms';
import { Register } from 'src/app/_classes/register';
import { RegisterService } from 'src/app/_services/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerModel = new Register();
  isResponse = 1;
  submitSuccess: number;
  isUser: number;
  requestFeedback: string;
  constructor(private registerService: RegisterService,
    private _router: Router,
    private data: DataService) { }
  @ViewChild(NgForm) registerRequest: NgForm;
  onSubmit(){
    this.isResponse =0;
    this.registerService.registerUser(this.registerModel).subscribe(
      (data) =>{
        this.isResponse =1;
        if (data.status == 1){
          this.submitSuccess = 1;
          
          localStorage.setItem("_TUSERNAME",this.registerModel.phoneNumber);
          this._router.navigate(["/account-verification"])
        }else{
          this.submitSuccess = 0;
          this.requestFeedback = data.data;
        }
      } 
    )
  }
  ngOnInit() {
  }

}
