import { GlobalAppConfigurationService } from '../_services/global-app-configuration.service';

export default class AppConstants {
    //192.168.0.109:80/vdb <-> 35.227.48.185
    static _COMPANY_NAME = "Amensa";
    static _COMPANY_PHONE_NUMBER_ = "+91-7039394488";
    static _COMPANY_EMAIL_ADDRESS_ = "support@dial4health.com";
    static _API_DOMAIN_ = "https://api.dial4health.com";
    static _API_KEY_  = "123";
    static _CURRENCY_SYS_ = "₹";
    static _WEIGHT_UNIT_ = "Kg";
    static _GET_CONFIG_URL_                  = AppConstants._API_DOMAIN_+"/config/global?api_key="+AppConstants._API_KEY_;
    static _META_DATA_URL_                  = AppConstants._API_DOMAIN_+"/meta-data?api_key="+AppConstants._API_KEY_;
    static _BLOOD_TESTS_URL_                = AppConstants._API_DOMAIN_+"/tests/get?api_key="+AppConstants._API_KEY_;
    static _GET_MEDICAL_EQUIPMENT_URL_      = AppConstants._API_DOMAIN_+"/medical-equipment/get?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOMER_PROFILE_URL_       = "";
    static _POST_NURSING_ENQUIRY_URL_       = AppConstants._API_DOMAIN_+"/nursing/enquiry?api_key="+AppConstants._API_KEY_;
    static _POST_RADIOLOGY_ENQUIRY_URL_     = AppConstants._API_DOMAIN_+"/radiology/enquiry?api_key="+AppConstants._API_KEY_;
    static _POST_PHYSIOTHERAPY_ENQUIRY_URL_ = AppConstants._API_DOMAIN_+"/physiotherapy/enquiry?api_key="+AppConstants._API_KEY_;
    static _POST_MEDICAL_INSURANCE_ENQUIRY_URL_ = AppConstants._API_DOMAIN_+"/medical-insurance/enquiry?api_key="+AppConstants._API_KEY_;
    static _POST_CUSTOMER_REGISTER_URL      = AppConstants._API_DOMAIN_+"/customer/register?api_key="+AppConstants._API_KEY_;
    static _POST_CHECK_USERNAME_URL_        = AppConstants._API_DOMAIN_+"/auth/check-username?api_key="+AppConstants._API_KEY_;
    static _POST_AUTH_CUSTOMER_URL_         = AppConstants._API_DOMAIN_+"/auth/customer?api_key="+AppConstants._API_KEY_;
    static _POST_AUTH_RESEND_OTP_URL_       = AppConstants._API_DOMAIN_+"/auth/customer/otp/resend?api_key="+AppConstants._API_KEY_;
    static _POST_CUSTOMER_PHONE_VERIFY_URL_ = AppConstants._API_DOMAIN_+"/customer/register/verify/phone?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOMER_DASHBOARD_COUNT_URL_ = AppConstants._API_DOMAIN_+"/customer/service/request/count?api_key="+AppConstants._API_KEY_;
    static _GET_FEATURED_PACKAGES_URL_      = AppConstants._API_DOMAIN_+"/packages/get/featured?api_key="+AppConstants._API_KEY_;
    static _POST_PRESCRIPTION_URL_          = AppConstants._API_DOMAIN_+"/prescription/upload?api_key="+AppConstants._API_KEY_;
    static _POST_FEEDBACK_URL_              = AppConstants._API_DOMAIN_+"/feedback/create?api_key="+AppConstants._API_KEY_;
    static _POST_CONTACT_US_URL_            = AppConstants._API_DOMAIN_+"/customer/message?api_key="+AppConstants._API_KEY_;
    static _POST_CALLBACK_URL_              = AppConstants._API_DOMAIN_+"/customer/call-back?api_key="+AppConstants._API_KEY_;
    static _GET_FAQ_URL_                    = AppConstants._API_DOMAIN_+"/faq?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOMER_ADDRESS_URL_       = AppConstants._API_DOMAIN_+"/customer/address?api_key="+AppConstants._API_KEY_;
    static _POST_CUSTOMER_ADDRESS_URL       = AppConstants._API_DOMAIN_+"/customer/address/create?api_key="+AppConstants._API_KEY_;
    static _PUT_CUSTOMER_ADDRESS_URL_       = AppConstants._API_DOMAIN_+"/customer/address/update?api_key="+AppConstants._API_KEY_;
    static _DELETE_CUSTOMER_ADDRESS_URL     = AppConstants._API_DOMAIN_+"/customer/address/delete?api_key="+AppConstants._API_KEY_;
    static _POST_ORDER_TEST_PACKAGE_URL     = AppConstants._API_DOMAIN_+"/order/test-package?api_key="+AppConstants._API_KEY_;
    static _GET_ORDER_TEST_PACKAGE_STATUS_URL= AppConstants._API_DOMAIN_+"/order/test-package/status?api_key="+AppConstants._API_KEY_;
    static _GET_ORDER_BY_ID_URL             = AppConstants._API_DOMAIN_+"/order/track?api_key="+AppConstants._API_KEY_;
    static _GET_ORDER_TEST_PACKAGE_URL      = AppConstants._API_DOMAIN_+"/order/test-package?api_key="+AppConstants._API_KEY_;
    static _POST_CUSTOM_ORDER_TEST_PACKAGE_URL     = AppConstants._API_DOMAIN_+"/order/custom-test-package?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOM_ORDER_TEST_PACKAGE_STATUS_URL= AppConstants._API_DOMAIN_+"/order/custom-test-package/status?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOM_ORDER_BY_ID_URL             = AppConstants._API_DOMAIN_+"/order/custom-track?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOM_ORDER_TEST_PACKAGE_URL      = AppConstants._API_DOMAIN_+"/order/custom-test-package?api_key="+AppConstants._API_KEY_;

    static _POST_MEDICAL_EQUIPMENT_ORDER_BUY_URL_     = AppConstants._API_DOMAIN_+"/order/medical-equipment-buy?api_key="+AppConstants._API_KEY_;
    static _GET_MEDICAL_EQUIPMENT_ORDER_BUY_STATUS_URL_= AppConstants._API_DOMAIN_+"/order/medical-equipment-buy/status?api_key="+AppConstants._API_KEY_;
    static _GET_MEDICAL_EQUIPMENT_ORDER_BY_ID_URL_     = AppConstants._API_DOMAIN_+"/order/medical-equipment-buy/track?api_key="+AppConstants._API_KEY_;
    static _GET_MEDICAL_EQUIPMENT_ORDER_BUY_URL_      = AppConstants._API_DOMAIN_+"/order/medical-equipment-buy?api_key="+AppConstants._API_KEY_;

    static _GET_PACKAGES_URL_               = AppConstants._API_DOMAIN_+"/packages/getAll?api_key="+AppConstants._API_KEY_;
    static _GET_ALL_CITIES_URL_             = AppConstants._API_DOMAIN_+"/cities?api_key="+AppConstants._API_KEY_;
    static _GET_PARTNERS_URL_               = AppConstants._API_DOMAIN_+"/partner/getAll?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOMER_NURSING_REQUEST_URL_ = AppConstants._API_DOMAIN_+"/customer/nursing/request?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOMER_RADIOLOGY_REQUEST_URL_ = AppConstants._API_DOMAIN_+"/customer/radiology/request?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOMER_PHYSIOTHERAPY_REQUEST_URL_ = AppConstants._API_DOMAIN_+"/customer/physiotherapy/request?api_key="+AppConstants._API_KEY_;
    static _GET_CUSTOMER_PRESCRIPTIONS_REQUEST_URL_ = AppConstants._API_DOMAIN_+"/customer/prescriptions/request?api_key="+AppConstants._API_KEY_;
    
    static _GET_TESTIMONIAL_URL_ = AppConstants._API_DOMAIN_+"/testimonial/get?api_key="+AppConstants._API_KEY_;

    static _POST_PROFILE_UPDATE_URL_      = AppConstants._API_DOMAIN_+"/customer/profile/update?api_key="+AppConstants._API_KEY_;
    static _GET_PROFILE_URL_              = AppConstants._API_DOMAIN_+"/customer/profile/get?api_key="+AppConstants._API_KEY_;
    static _POST_PASSWORD_UPDATE_URL_     = AppConstants._API_DOMAIN_+"/customer/password/update?api_key="+AppConstants._API_KEY_;
    private GlobalAppConfiguration: GlobalAppConfigurationService;
    constructor(){
      
    }
    getConfigurations(){
      this.GlobalAppConfiguration.getConfiguration().subscribe((data)=>{
        let d = data.data[0];
        AppConstants._COMPANY_NAME = d.page_title;
      });
    }
  }