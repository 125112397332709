import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MedicalInsuranceData } from '../_classes/medical-insurance-data';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class MedicalInsuranceService {
  constructor(private _http: HttpClient) { }
  submitEnquiry(medicalInsuranceData: MedicalInsuranceData){
    let postData = new FormData();
    postData.append("full_name",medicalInsuranceData.fullName);
    postData.append("phone_number",""+medicalInsuranceData.phoneNumber);
    postData.append("email_id",medicalInsuranceData.emailId);
    postData.append("address",medicalInsuranceData.address);
    postData.append("message",medicalInsuranceData.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_MEDICAL_INSURANCE_ENQUIRY_URL_,postData,httpOptions);
  }
}
