import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AllPackagesService } from 'src/app/_services/all-packages.service';
import AppConstants from 'src/app/config/AppConstants';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-all-packages',
  templateUrl: './all-packages.component.html',
  styleUrls: ['./all-packages.component.css']
})
export class AllPackagesComponent implements OnInit {
  public AllPackagesData: any;
  public baseUrl: string;
  public isLogin: number;
  public isLearnMore: number;
  public selectedPackage: any;
  public isResponse = 0;
  constructor(private titleService: Title,private AllPackagesService: AllPackagesService,private LoginService: LoginService) { }

  ngOnInit() {
    this.titleService.setTitle("All Packages  | "+AppConstants._COMPANY_NAME);
    if (this.LoginService.isAuthenticated()){
      this.isLogin = 1;
    }else{
      this.isLogin = 0;
    }
    this.AllPackagesService.getAll().subscribe((data)=>{
      console.log(data.data);
      this.isResponse = 1;
      this.AllPackagesData = data;
    });

  }
  selectPackage(p: any){
    this.selectedPackage = p;
    this.isLearnMore = 1;
  }

}
