import { Component, OnInit, ViewChild } from '@angular/core';
import { RegisterVerify } from 'src/app/_classes/register-verify';
import { RegisterService } from 'src/app/_services/register.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/_services/data.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  registerVerifyModel = new RegisterVerify();
  isResponse = 1;
  submitSuccess: number;
  isUser: number;
  requestFeedback: string;
  constructor(private registerService: RegisterService,
    private _router: Router,
    private data: DataService) { }
  @ViewChild(NgForm) registerVerifyRequest: NgForm;
  onSubmit(){
    this.isResponse = 0;
    this.registerService.verifyOTP(this.registerVerifyModel).subscribe(
      (data) =>{
        this.isResponse = 1;
        if (data.status == 1){
          this.submitSuccess = 1;
          localStorage.clear();
          localStorage.setItem("_UID",data.data.uid);
          localStorage.setItem("_USERNAME",data.data.username);
          localStorage.setItem("_FIRST_NAME",data.data.first_name);
          localStorage.setItem("_LAST_NAME",data.data.last_name);
          this.data.changeLoginStatus(1);
          this._router.navigate(["/dashboard"]);
          
        }else{
          this.submitSuccess = 0;
          this.requestFeedback = data.data;
        }
      } 
    )
  }
  ngOnInit() {
  }

}
