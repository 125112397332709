import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(private http: HttpClient) { }
  getCities(){
    return this.http.get<any>(AppConstants._GET_ALL_CITIES_URL_);
  } 
}
