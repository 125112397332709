import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrderLabTest } from '../_classes/order-lab-test';
import AppConstants from '../config/AppConstants';
import { DataService } from './data.service';
import { OrderCustomLabTest } from '../_classes/order-custom-lab-test';

@Injectable({
  providedIn: 'root'
})
export class OrderLabTestService {
  uid: string;
  constructor(private _http: HttpClient,private _data: DataService) {
    this._data.uid.subscribe(
      uid => this.uid = uid
    );
   }
  getAll(){
    return this._http.get<any>(AppConstants._GET_ORDER_TEST_PACKAGE_URL+"&uid="+this.uid);
  }
  getOrderById(id: string){
    return this._http.get<any>(AppConstants._GET_ORDER_BY_ID_URL+"&order_id="+id);
  }
  checkOrderStatus(orderId: string){
    return this._http.get<any>(AppConstants._GET_ORDER_TEST_PACKAGE_STATUS_URL+"&order_id="+orderId);
  }
  checkCustomOrderStatus(orderId: string){
    return this._http.get<any>(AppConstants._GET_CUSTOM_ORDER_TEST_PACKAGE_STATUS_URL+"&order_id="+orderId);
  }
  sendCustomOrderRequest(orderCustomLabTestData: OrderCustomLabTest){
    let postData = new FormData();
    postData.append("uid",this.uid);
    postData.append("address_id",orderCustomLabTestData.addressId);
    postData.append("patient_age",orderCustomLabTestData.patientAge);
    postData.append("date_time_slot",orderCustomLabTestData.dateTimeSlot);
    postData.append("payment_mode",orderCustomLabTestData.paymentMode);
    postData.append("patient_name",orderCustomLabTestData.patientName);
    postData.append("test_id",orderCustomLabTestData.testIds);
    postData.append("price",orderCustomLabTestData.price.toString());
    postData.append("message",orderCustomLabTestData.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_CUSTOM_ORDER_TEST_PACKAGE_URL,postData,httpOptions);
  }
  sendOrderRequest(orderLabTestData: OrderLabTest){
    let postData = new FormData();
    postData.append("uid",this.uid);
    postData.append("address_id",orderLabTestData.addressId);
    postData.append("patient_age",orderLabTestData.patientAge);
    postData.append("date_time_slot",orderLabTestData.dateTimeSlot);
    postData.append("payment_mode",orderLabTestData.paymentMode);
    postData.append("patient_name",orderLabTestData.patientName);
    postData.append("package_id",orderLabTestData.packageId);
    postData.append("price",orderLabTestData.price);
    postData.append("message",orderLabTestData.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_ORDER_TEST_PACKAGE_URL,postData,httpOptions);
  }
}
