import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private _router: Router,private data: DataService) { }

  ngOnInit() {
    this.data.changeLoginStatus(0);
    localStorage.clear();
    this._router.navigate(['']);
  }

}
