import { Component, OnInit, ViewChild } from '@angular/core';
import { PrescriptionForm } from 'src/app/_classes/prescription-form';
import { UploadPrescriptionService } from 'src/app/_services/upload-prescription.service';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import AppConstants from 'src/app/config/AppConstants';
import { LoginService } from 'src/app/_services/login.service';
import { ProfileService } from 'src/app/_services/profile.service';
// declare function disableButton(id: string): any;
// declare function enableButton(id: string): any
@Component({
  selector: 'app-upload-prescription',
  templateUrl: './upload-prescription.component.html',
  styleUrls: ['./upload-prescription.component.css']
})
export class UploadPrescriptionComponent implements OnInit {
  isResponse = 1;
  prescriptionForm = new PrescriptionForm();
  submitSuccess= 0;
  error: string;
  message: string;
  fileSelected = 0;
  uploadResponse= { status: '', message: '', filePath: '' };
  constructor(private titleService: Title,
    private prescriptionService: UploadPrescriptionService,
    private LoginService: LoginService,
    private ProfileService: ProfileService) { }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      //console.log(file);
      //this.form.get('avatar').setValue(file);
      this.prescriptionForm.prescription = file;
      this.fileSelected = 1;
    }
  }
  @ViewChild(NgForm) prescriptionFormRequest: NgForm;
  onSubmit(){
    this.isResponse =0;
    if (this.fileSelected == 1){
      this.prescriptionService.uploadData(this.prescriptionForm).subscribe(
        
        (data) =>{
          this.isResponse =1;
          this.fileSelected = 0;
          this.uploadResponse = data;
          //enableButton("submitButton");
          //this.loadingStarted = data.message;
          if (data.status == 1){
            this.submitSuccess = 1;
            this.message = data.data;
            this.prescriptionFormRequest.resetForm();
          }else{
            this.message = data.data;
            this.submitSuccess = 0;
          }
        } 
      )
    }
  }
  ngOnInit() {
    this.titleService.setTitle("Upload Prescription  | "+AppConstants._COMPANY_NAME);
    if (this.LoginService.isAuthenticated()){
      
      this.ProfileService.getProfile(this.LoginService.getUid()).subscribe((data)=>{
        this.prescriptionForm.fullName = data.data.full_name;
        this.prescriptionForm.emailId = data.data.email_id;
        this.prescriptionForm.phoneNumber = data.data.phone_number;
      });
    }
  }
}
