import { Component, OnInit } from '@angular/core';
import { LoginService } from '../_services/login.service';
import { DataService } from '../_services/data.service';
import AppConstants from '../config/AppConstants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isLogin: number;
  public phoneNumber: string;
  constructor(private loginService: LoginService,private data: DataService) { }

  ngOnInit() {
    this.phoneNumber = AppConstants._COMPANY_PHONE_NUMBER_;
    this.data.isLogin.subscribe(
      isLogin => this.isLogin = isLogin
    );
    if (this.loginService.isAuthenticated()){
      this.isLogin = 1;
    }else{
      this.isLogin = 0;
    }
  }
  

}
