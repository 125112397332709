import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrderCustomLabTest } from '../_classes/order-custom-lab-test';
import AppConstants from '../config/AppConstants';
import { OrderMedicalEquipmentBuy } from '../_classes/order-medical-equipment-buy';
import { post } from 'selenium-webdriver/http';

@Injectable({
  providedIn: 'root'
})
export class OrderMedicalEquipmentService {
  public uid: string;
  constructor(private _data: DataService, 
    private _http: HttpClient, 
    ) { 
    this._data.uid.subscribe(
      uid => this.uid = uid
    );

  }
  getAll(){
    return this._http.get<any>(AppConstants._GET_MEDICAL_EQUIPMENT_ORDER_BUY_URL_+"&uid="+this.uid);
  }
  getOrderById(id: string){
    return this._http.get<any>(AppConstants._GET_MEDICAL_EQUIPMENT_ORDER_BY_ID_URL_+"&order_id="+id);
  }
  checkOrderStatus(orderId: string){
    return this._http.get<any>(AppConstants._GET_MEDICAL_EQUIPMENT_ORDER_BUY_STATUS_URL_+"&order_id="+orderId);
  }
  sendOrderRequest(OrderMedicalEquipmentData: OrderMedicalEquipmentBuy){
    let postData = new FormData();
    postData.append("uid",this.uid);
    postData.append("address_id",OrderMedicalEquipmentData.addressId);
    postData.append("payment_mode",OrderMedicalEquipmentData.paymentMode);
    postData.append("equipment_id",OrderMedicalEquipmentData.medicalEquipmentId);
    postData.append("invoice_address_id",OrderMedicalEquipmentData.addressId);
    postData.append("price",OrderMedicalEquipmentData.price.toString());
    postData.append("message",OrderMedicalEquipmentData.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_MEDICAL_EQUIPMENT_ORDER_BUY_URL_,postData,httpOptions);
  }
}
