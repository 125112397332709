import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import AppConstants from 'src/app/config/AppConstants';
import { MedicalEquipmentsService } from 'src/app/_services/medical-equipments.service';
import { MedicalEquipment } from 'src/app/_interface/medical-equipment';


@Component({
  selector: 'app-medical-equipments',
  templateUrl: './medical-equipments.component.html',
  styleUrls: ['./medical-equipments.component.css']
})
export class MedicalEquipmentsComponent implements OnInit {
  public medicalEquipmentData: MedicalEquipment;
  public baseUrl: string;
  public INR = AppConstants._CURRENCY_SYS_;
  public isResponse = 0; 
  constructor(private titleService: Title,private medicalEquipmentService: MedicalEquipmentsService) { 
    this.baseUrl = AppConstants._API_DOMAIN_;
  }
  
  ngOnInit() {
    this.titleService.setTitle("Medical Equipments  | "+AppConstants._COMPANY_NAME);
    this.medicalEquipmentService.getData().subscribe((data)=>{
      console.log(data.data);
      this.isResponse = 1;
      this.medicalEquipmentData = data;
    })
  }

}
