import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import AppConstants from 'src/app/config/AppConstants';
import { NgForm } from '@angular/forms';
import { ContactForm } from 'src/app/_classes/contact-form';
import { ContactFormService } from 'src/app/_services/contact-form.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactFormData = new ContactForm();
  submitSuccess= 0;
  isResponse = 1;
  constructor(private titleService: Title,private contactFormService: ContactFormService) { }
  @ViewChild(NgForm) contactForm: NgForm;
  ngOnInit() {
    this.titleService.setTitle("Contact us  | "+AppConstants._COMPANY_NAME);
  }
  onSubmit(){
    this.isResponse = 0;
    this.contactFormService.createEnquiry(this.contactFormData).subscribe(
      (data) =>{
        if (data.status == "1"){
        this.submitSuccess = 1;
        this.contactForm.resetForm();
        }else{
          this.submitSuccess = 0;
        }
        this.isResponse = 1;
      } 
    )
  }
  resetFlags(){
    this.submitSuccess = 0;
  }
}
