import { Injectable } from '@angular/core';
import { LoginService } from '../_services/login.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private _authService: LoginService, private _router: Router) { }
  // canDeactivate() {
  //     // if the editName !== this.user.name
  //     if (this._authService.isAuthenticated()) {
  //       return false;
  //     }
  //     this._router.navigate(['/dashboard']);
  //     return true;
  // }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isAuthenticated()) {
        return true;
    }

    // navigate to login page
    this._router.navigate(['/login']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }
}
