import { Component, OnInit } from '@angular/core';
import { OrderLabTestService } from 'src/app/_services/order-lab-test.service';

@Component({
  selector: 'app-blood-tests',
  templateUrl: './blood-tests.component.html',
  styleUrls: ['./blood-tests.component.css']
})
export class BloodTestsComponent implements OnInit {
  orderData: any;
  isOrders: number;
  constructor(
    private orderLabTestService: OrderLabTestService
  ) { }

  ngOnInit() {
    this.orderLabTestService.getAll().subscribe(
      (data)=>{
        
        if (data.status){
          this.orderData = data.data;
          this.isOrders = 1;
        }else{
          this.isOrders = 0;
          this.orderData = 0;
        }
      }
    );
    
  }

}
