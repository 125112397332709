import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProfileForm } from 'src/app/_classes/profile-form';
import { ProfileService } from 'src/app/_services/profile.service';
import { LoginService } from 'src/app/_services/login.service';
import { DataService } from 'src/app/_services/data.service';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.css']
})
export class UserPasswordComponent implements OnInit {
  public profileData = new ProfileForm();
  public _uid: string;
  public isResponse = 0;
  constructor(
    private _profileService: ProfileService,
    private LoginService: LoginService,
    private _DataService: DataService) { }
    @ViewChild(NgForm) profileDataForm: NgForm;  

  ngOnInit() {
    this._uid = this.LoginService.getUid();
  }
  onSubmit(){

  }
  updatePassword(){
    if (this.profileDataForm.form.valid){
      this.isResponse = 0;
      this._profileService.updateProfilePassword(this.profileData).subscribe(
        (data)=>{
          //process
          //console.log(data);
          this.isResponse =1;
          
          
          
        });
    }
  }

}
