export class CustomerAddress {
    public alias: string;
    public addressLine1: string;
    public addressLine2: string;
    public city: string;
    public state: string;
    public country: string;
    public pinCode: string;
    public landMark: string;
    constructor(){}
}
