import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-equipment-purchase-records',
  templateUrl: './medical-equipment-purchase-records.component.html',
  styleUrls: ['./medical-equipment-purchase-records.component.css']
})
export class MedicalEquipmentPurchaseRecordsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
