import { Component, OnInit, ViewChild } from '@angular/core';
import { Login } from 'src/app/_classes/login';
import { NgForm } from '@angular/forms';
import { LoginService } from 'src/app/_services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/_services/data.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginModel = new Login();
  showPasswordField: number;
  showOTPField: number;
  isUser: number;
  redirect: string;
  requestFeedback: string;
  isResponse = 1;
  constructor(private loginService: LoginService,
              private _router: Router,
              private data: DataService,
              private _activeRoute: ActivatedRoute
              ) { }
  resendOTP(){
    this.loginService.resendOTP(this.loginModel).subscribe(
      (data) =>{
        if (data.status == 1){
          this.requestFeedback=data.data;
        }
      } 
    )
  }
  @ViewChild(NgForm) loginRequest: NgForm;
  onSubmit(){
    this.isResponse = 0;
    this.redirect = this._activeRoute.snapshot.paramMap.get("redirect");
    if ((this.loginModel.OTP || this.loginModel.password ) && this.loginModel.username){
        //console.log("send final login request");
        //console.log("username"+this.loginModel.username+" and  password/otp:"+this.loginModel.OTP||this.loginModel.password);
      this.loginService.customerLogin(this.loginModel).subscribe(
        (data)=>{
          //console.log(data);
          this.isResponse = 1;
          if (data.status == 0){
            this.requestFeedback = data.data;
          }else{
            localStorage.setItem("_UID",data.data.uid);
            localStorage.setItem("_USERNAME",data.data.username);
            localStorage.setItem("_FIRST_NAME",data.data.first_name);
            localStorage.setItem("_LAST_NAME",data.data.last_name);
            this.data.changeLoginStatus(1);
            if (this.redirect ){
              this._router.navigate([this.redirect.replace(/_/g,"/")]);
            }else{
              this._router.navigate(['/dashboard']);
            }
           
          }
        }
      );
    }else{
      this.loginService.checkUser(this.loginModel).subscribe(
        
        (data) =>{
          this.isResponse = 1;
          if (data.data.isUser == 1 && data.data.passwordSet == 1){
            //that means user has set the password show the password field 
            this.showPasswordField = 1;
            this.showOTPField = 0;
            this.isUser = 1;
          }else if (data.data.isUser == 1 && data.data.passwordSet == 0){
            this.showOTPField = 1;
            this.showPasswordField = 0;
            this.isUser = 1;
            this.requestFeedback = "OTP is send on registered Mobile Number.";
          }else{
            this.showOTPField =0;
            this.showPasswordField=0;
            this.isUser = 0;
            
          }
        } 
      )
    }
  }
  ngOnInit() {
    if (this.loginService.isAuthenticated()){
      this._router.navigate(['/dashboard']);
    }
  }
  

}
