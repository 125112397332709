import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-equipment-terms',
  templateUrl: './medical-equipment-terms.component.html',
  styleUrls: ['./medical-equipment-terms.component.css']
})
export class MedicalEquipmentTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
