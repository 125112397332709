import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import AppConstants from 'src/app/config/AppConstants';
import { HttpClient } from '@angular/common/http';
import { FeaturedPackagesService } from 'src/app/_services/featured-packages.service';
import { FaqService } from 'src/app/_services/faq.service';
import { DataService } from 'src/app/_services/data.service';
import { LoginService } from 'src/app/_services/login.service';
import { BloodTestService } from 'src/app/_services/blood-test.service';
import { SelectedTests } from 'src/app/_interface/selected-tests';
import {  Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { SearchForm } from 'src/app/_classes/search-form';
import { TestimonialService } from 'src/app/_services/testimonial.service';
interface dataStore{
  highlightTests: highlightTestsStore[];
}
interface highlightTestsStore{
  id: Number;
  test_name: String;
}
interface metaStore{
  status: Number;
  data: dataStore;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public metaData: metaStore;
  public featuredPackages: any; 
  public faqs: any;
  public isLogin: number;
  public tests: any;
  public selectTestItem: any;
  public isDropDownOpen: number;
  public searchFormData = new SearchForm();
  public currencySymbol = AppConstants._CURRENCY_SYS_;
  public query: string;
  public TestimonialData: any;
  public isResponse = 0;
  public loadPipe = 1;
  constructor(
    private titleService: Title,
    private httpClient: HttpClient,
    private _featuredPackage: FeaturedPackagesService,
    private _faq: FaqService ,
    private data: DataService,
    private loginService: LoginService,
    private TestService: BloodTestService,
    private _router: Router,
    private _TestimonialService: TestimonialService
  ) { }
  @ViewChild(NgForm) searchForm: NgForm;
  ngOnInit() {
    this._TestimonialService.getAll().subscribe((data)=>{
      this.TestimonialData = data.data;
    });
    this.data.isLogin.subscribe(
      isLogin => this.isLogin = isLogin
    );
    if (this.loginService.isAuthenticated()){
      this.isLogin = 1;
    }else{
      this.isLogin = 0;
    }
    this.titleService.setTitle("Amensa - Blood Test @Doorstep - Medical Equipment on Rent - Nursing "+AppConstants._COMPANY_NAME);
    //set meta data
    this.httpClient.request(
      "GET",
      AppConstants._META_DATA_URL_, 
      {
          responseType:"json",
      }).subscribe((res : metaStore)=>{
      //console.log(res);
      this.metaData = res;
    });
    //BloodTests
    this.TestService.getTests().subscribe(
      (data: any)=>{
        this.tests = data.data;
      }
    );
    //fetch featured package
    this._featuredPackage.getData().subscribe(
      (data)=>{
        this.featuredPackages = data;
      }
    );
      //fetch faq
    this._faq.getFAQs().subscribe(
      (data: any)=>{
        this.faqs = data.data;
        this.isResponse = 1;
      }
    );
    
    
  }
  startSearching(q:string){
    this.query = this.searchFormData.query;
    console.log(this.query);
  }
  filterSearch(tests:any){
    if (this.query){
      return tests.filter(x => {
        return x.test_name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0;
      });
    }else{
      return this.tests;
    }
  }
  submitCustomTests(){
    if (this.filterSelectedTests(this.tests).length >= 1){
    this.data.changeSelectedTests(this.filterSelectedTests(this.tests));
      if (this.isLogin == 1){
        this._router.navigate(['/order/custom-test-package']);
      }else{
        this._router.navigate(['/login/order_custom-test-package']); 
      }
    }else{
      this.openDropDown();
    }
  }
  filterSelectedTests(test:any){
    return this.tests.filter(x => {
      return x.selected == 1;
    });
  }
  selectTest(test: any){
    this.selectTestItem = test;
    this.tests.forEach(element => {
      if (element.id === this.selectTestItem.id){
        element.selected = 1;
      }
    });
  }
  removeSelectedItem(test: any){
    this.selectTestItem = test;
    this.tests.forEach(element => {
      if (element.id === this.selectTestItem.id){
        element.selected = 0;
      }
    });
  }
  openDropDown(){
    this.isDropDownOpen = 1;
  }
  closeDropDown(){
    this.isDropDownOpen = 0;
    this.tests.forEach(element => {
        element.selected = 0;
    });
  }
}
