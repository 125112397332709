import { Injectable } from '@angular/core';
import { GetCallBackForm } from '../_classes/get-call-back-form';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class GetCallBackFormService {
  
  constructor(private _http: HttpClient) { }
  createEnquiry(GetCallBackForm: GetCallBackForm){
    let postData = new FormData();
    postData.append("full_name",GetCallBackForm.fullName);
    postData.append("phone_number",""+GetCallBackForm.phoneNumber);
    postData.append("email_id",GetCallBackForm.emailId);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_CALLBACK_URL_,postData,httpOptions);
  }
}

