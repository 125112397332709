import { Component, OnInit } from '@angular/core';
import { CustomerAddressService } from 'src/app/_services/customer-address.service';

@Component({
  selector: 'app-customer-address',
  templateUrl: './customer-address.component.html',
  styleUrls: ['./customer-address.component.css']
})
export class CustomerAddressComponent implements OnInit {
  addressData: any;
  isResponse = 0;
  constructor(private customerAddressService: CustomerAddressService) { }
  deleteAddress(id: string){
    if (confirm("Are You Sure you want to delete this address ?") == true){
      this.isResponse = 0;
      this.customerAddressService.deleteAddress(id).subscribe(
        (data) =>{
          this.customerAddressService.getAll().subscribe(
            (data) =>{
              this.isResponse = 1;
              this.addressData = data.data;
            } 
          );
        }
      );
    }
  }
  
  ngOnInit() {
    this.customerAddressService.getAll().subscribe(
      (data) =>{
        this.isResponse = 1;
        this.addressData = data.data;
      } 
    );
  }

}
