import { Component, OnInit, ViewChild } from '@angular/core';
import AppConstants from 'src/app/config/AppConstants';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FeaturedPackagesService } from 'src/app/_services/featured-packages.service';
import { CustomerAddressService } from 'src/app/_services/customer-address.service';
import { NgForm } from '@angular/forms';
import { OrderLabTestService } from 'src/app/_services/order-lab-test.service';
import { OrderLabTest } from 'src/app/_classes/order-lab-test';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-order-lab-tests',
  templateUrl: './order-lab-tests.component.html',
  styleUrls: ['./order-lab-tests.component.css']
})
export class OrderLabTestsComponent implements OnInit {
  id: string;
  success: any;
  baseUrl: string;
  featuredPackage: any;
  addressData: any;
  orderLabTestData = new OrderLabTest();
  requestResponse: string;
  paymentMode: string;
  transactionStarted: boolean;
  
  public isResponse = 0;
  constructor(
    private route: ActivatedRoute,
    private featuredPackageService: FeaturedPackagesService,
    private customerAddressService: CustomerAddressService,
    private orderLabTestService: OrderLabTestService,
    private titleService: Title,
    private _router: Router) {
    this.baseUrl = AppConstants._API_DOMAIN_;
  }
  @ViewChild(NgForm) orderLabTestForm: NgForm;
  ngOnInit() {
   
    this.titleService.setTitle("Order Test Package  | " + AppConstants._COMPANY_NAME);
    this.getAddress();
    this.id = this.route.snapshot.paramMap.get("id");
    this.featuredPackageService.getById(this.id).subscribe((data) => {
      //console.log(data.data);
      this.isResponse = 1;
      this.featuredPackage = data.data[0];
    })
  }
  onSubmit() {

  }
  handleDatePicker(e){
    console.log(this.orderLabTestForm);
  }
  processPaymentOrder() {
   
    if (this.orderLabTestForm.form.valid) {
      this.isResponse = 0;
      this.transactionStarted = true;
      this.orderLabTestData.paymentMode = "online";
      this.orderLabTestData.packageId = this.featuredPackage.id;
      this.orderLabTestData.price = (this.featuredPackage.dis_price >= 1 ? this.featuredPackage.dis_price : this.featuredPackage.package_price);
      this.paymentMode = "online";
      this.orderLabTestService.sendOrderRequest(this.orderLabTestData).subscribe(
        (data) => {
          this.isResponse =1;
          if (data.status == 1) {
            //this.requestResponse = data.data;
            let win = window.open("", "win", "fullscreen=yes"); // a window object
            win.document.open("text/html", "replace");
            win.document.write(data.data.html);
            win.document.close();

            let interval = setInterval(()=>{
              this.orderLabTestService.checkOrderStatus(data.data.order_id).subscribe(
                (response)=>{
                  console.log(response);
                  this.requestResponse = response.data.message;
                  if (response.status == 1){ //transaction successful
                    //redirect to order confirmation page.
                    if (response.data.message == "success"){
                      //navigate to order confirmation page
                      this.requestResponse = "Navigate to Order Confirmation Page."
                      this._router.navigate(["/dashboard/order/"+data.data.order_id]);
                    }
                    this.transactionStarted = false;
                    clearInterval(interval);
                  }
                });
            },1000);

          }
        }
      );
    }
  }
  getAddress(){
    this.customerAddressService.getAll().subscribe(
      (data) => {
        this.addressData = data.data;
      }
    );
  }
  refreshAddress(e){
    if(e == 1){
      this.getAddress();
    }
  }
  processOfflineOrder() {
    this.isResponse =0;
    if (this.orderLabTestForm.form.valid) {
      this.orderLabTestData.paymentMode = "offline";
      this.orderLabTestData.packageId = this.featuredPackage.id;
      this.orderLabTestData.price = (this.featuredPackage.dis_price >= 1 ? this.featuredPackage.dis_price : this.featuredPackage.package_price);
      this.paymentMode = "offline";
      this.orderLabTestService.sendOrderRequest(this.orderLabTestData).subscribe(
        (data) => {
          //console.log(data);
          this.isResponse = 1;
          if (data.status == 1) {
            this.success = "1";
            this._router.navigate(["/dashboard/order/"+data.data.order_id]);
            this.requestResponse = data.data;
            this.orderLabTestForm.resetForm();
          }
        }
      );
    }
  }
}
