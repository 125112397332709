import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent implements OnInit {
  days: any[];
  months: [];
  years: [];
  i: number;
  @Input() startDay: number;
  @Input() endDay: number;
  @Input() startMonth: number;
  @Input() endMonth: number;
  @Input() startYear: number;
  @Input() endYear: number;
  constructor() { }

  ngOnInit() {
    console.log(this.startDay);
    //if (this.startDay && this.endDay){
      for(let i=this.startDay;i<=this.endDay;i++){
        this.days[i] = i;
        console.log(i);
      }
    // }else{
    //   for(this.i=1;this.i<=31;this.i++){
    //     this.days.push(this.i);
    //   }
    // }
    console.log(this.days);
  }

}
