import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/_services/dashboard.service';


@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})
export class DashboardHomeComponent implements OnInit {
  public nursingCount = 0;
  public radiologyCount = 0;
  public physiotherapyCount = 0;
  public bloodTestCount = 0;
  public prescriptionsCount = 0;
  public medicalEquipmentCount =0;
  public medicalEquipmentRentCount =0;
  public isResponse = 0;
  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    this.dashboardService.getDashboardCount().subscribe(
      (data) => {
        this.isResponse = 1;
        this.nursingCount               = data.data.nursingCount;
        this.physiotherapyCount         = data.data.physiotherapyCount;
        this.radiologyCount             = data.data.radiologyCount;
        this.bloodTestCount             = data.data.bloodTestCount; 
        this.prescriptionsCount         = data.data.prescriptionsCount;
        this.medicalEquipmentCount      = data.data.medicalEquipmentCount;
        this.medicalEquipmentRentCount  = data.data.medicalEquipmentRentCount;
      }
    )
  }

}
