import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalEquipmentsService } from 'src/app/_services/medical-equipments.service';
import { MedicalEquipment } from 'src/app/_interface/medical-equipment';
import AppConstants from 'src/app/config/AppConstants';
import { DataService } from 'src/app/_services/data.service';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-equipment-by-id',
  templateUrl: './equipment-by-id.component.html',
  styleUrls: ['./equipment-by-id.component.css']
})
export class EquipmentByIdComponent implements OnInit {
  id: string;
  isLogin: number;
  medicalEquipment: MedicalEquipment;
  baseUrl: string;
  constructor(
        private route: ActivatedRoute,
        private medicalEquipmentService: MedicalEquipmentsService,
        private _data: DataService,
        private _user: LoginService) {
    this.baseUrl = AppConstants._API_DOMAIN_;
   }

  ngOnInit() {
    this._data.isLogin.subscribe(

      //isLogin => this.isLogin = isLogin
    );
    //this.isLogin = this._user.getUid();
    if (this._user.isAuthenticated()){
      this.isLogin = 1;
    }else{
      this.isLogin = 0;
    }
    
    console.log(this.isLogin);
    this.id = this.route.snapshot.paramMap.get("id");
    this.medicalEquipmentService.getById(this.id).subscribe((data)=>{
      console.log(data.data);
      this.medicalEquipment = data;
    })
  }

}
