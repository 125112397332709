import { Injectable } from '@angular/core';
import AppConstants from '../config/AppConstants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RadiologyEnquiry } from '../_classes/radiology-enquiry';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class RadiologyEnquiryService {
  public uid: string;
  constructor(private _http: HttpClient, private _data: DataService) {
    this._data.uid.subscribe(
      uid => this.uid = uid
    );  
  }
  getCustomerRequests(){
    return this._http.get<any>(AppConstants._GET_CUSTOMER_RADIOLOGY_REQUEST_URL_+"&uid="+this.uid);
  }
  submitEnquiry(radiologyEnquiry: RadiologyEnquiry){
    let postData = new FormData();
    postData.append("full_name",radiologyEnquiry.fullName);
    postData.append("phone_number",""+radiologyEnquiry.phoneNumber);
    postData.append("email_id",radiologyEnquiry.emailId);
    postData.append("address",radiologyEnquiry.address);
    postData.append("message",radiologyEnquiry.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_RADIOLOGY_ENQUIRY_URL_,postData,httpOptions);
  }
}
