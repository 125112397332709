import { Component, OnInit } from '@angular/core';
import { LoginService } from '../_services/login.service';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-user-top-bar',
  templateUrl: './user-top-bar.component.html',
  styleUrls: ['./user-top-bar.component.css']
})
export class UserTopBarComponent implements OnInit {
  public username: string;
  public firstName: string;
  constructor(private loginService: LoginService,private _Data: DataService) { }

  ngOnInit() {
    this.username = this.loginService.getUsername();
    this._Data.firstName.subscribe(
      (data) => {
        this.firstName = data;
      }
    );
    this.firstName = this.loginService.getFirstName();
  }

}
