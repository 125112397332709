import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerAddress } from '../_classes/customer-address';
import { DataService } from './data.service';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class CustomerAddressService {
  uid: string;
  constructor(private _http: HttpClient,private _data: DataService) { 
    this._data.uid.subscribe(
      uid => this.uid = uid
    );
  }
  getAll(){
    return this._http.get<any>(AppConstants._GET_CUSTOMER_ADDRESS_URL_+"&uid="+this.uid);
  }
  getById(id:string){
    return this._http.get<any>(
      AppConstants._GET_CUSTOMER_ADDRESS_URL_+"&id="+id
    );
  }
  createAddress(customerAddress: CustomerAddress){
    
    let postData = new FormData();
    postData.append("alias",customerAddress.alias); 
    postData.append("address_1",customerAddress.addressLine1);
    postData.append("address_2",customerAddress.addressLine2); 
    postData.append("city",customerAddress.city); 
    postData.append("state",customerAddress.state); 
    postData.append("country",customerAddress.country);
    postData.append("pin_code",customerAddress.pinCode); 
    postData.append("landmark",customerAddress.landMark);
    postData.append("uid",this.uid);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_CUSTOMER_ADDRESS_URL,postData,httpOptions);
  }
  updateAddress(customerAddress: CustomerAddress,id: string){
    let postData = new FormData();
    postData.append("alias",customerAddress.alias); 
    postData.append("address_1",customerAddress.addressLine1);
    postData.append("address_2",customerAddress.addressLine2); 
    postData.append("city",customerAddress.city); 
    postData.append("state",customerAddress.state); 
    postData.append("country",customerAddress.country);
    postData.append("pin_code",customerAddress.pinCode); 
    postData.append("landmark",customerAddress.landMark);
    postData.append("uid",this.uid);
    postData.append("id",id);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._PUT_CUSTOMER_ADDRESS_URL_,postData,httpOptions);
  }
  deleteAddress(id: string){
    return this._http.get<any>(AppConstants._DELETE_CUSTOMER_ADDRESS_URL+"&id="+id);
  }
}
