import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/_services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OrderLabTestService } from 'src/app/_services/order-lab-test.service';
import { CustomerAddressService } from 'src/app/_services/customer-address.service';
import { FeaturedPackagesService } from 'src/app/_services/featured-packages.service';
import AppConstants from 'src/app/config/AppConstants';
import { NgForm } from '@angular/forms';
import { OrderCustomLabTest } from 'src/app/_classes/order-custom-lab-test';

@Component({
  selector: 'app-order-custom-lab-tests',
  templateUrl: './order-custom-lab-tests.component.html',
  styleUrls: ['./order-custom-lab-tests.component.css']
})
export class OrderCustomLabTestsComponent implements OnInit {
  public selectedTests: any;
  id: string;
  success: any;
  baseUrl: string;
  featuredPackage: any;
  addressData: any;
  orderCustomLabTestData = new OrderCustomLabTest();
  requestResponse: string;
  paymentMode: string;
  transactionStarted: boolean;
  package_price: number = 0;
  testIds: string = "";
  isResponse = 1;
  constructor(private _data: DataService,
    private route: ActivatedRoute,
    private featuredPackageService: FeaturedPackagesService,
    private customerAddressService: CustomerAddressService,
    private orderLabTestService: OrderLabTestService,
    private titleService: Title,
    private _router: Router) {
    this.baseUrl = AppConstants._API_DOMAIN_; }
  @ViewChild(NgForm) orderCustomLabTestForm: NgForm;
  ngOnInit() {
    this._data.selectedTests.subscribe(
      d => this.selectedTests = d
    );
    this.selectedTests.forEach(element => {
      let p: number = parseInt(element.price);
      this.package_price = this.package_price+p;
      this.testIds = this.testIds+","+element.id;
    });
    this.titleService.setTitle("Order Custom Test Package  | " + AppConstants._COMPANY_NAME);
    this.getAddress();
  }
  handleDatePicker(e){
    console.log(this.orderCustomLabTestForm);
  }
  processPaymentOrder() {
    if (this.orderCustomLabTestForm.form.valid) {
      this.isResponse = 0;
      this.transactionStarted = true;
      this.orderCustomLabTestData.paymentMode = "online";
      this.orderCustomLabTestData.testIds = this.testIds;
      this.orderCustomLabTestData.price = this.package_price;
      this.paymentMode = "online";
      this.orderLabTestService.sendCustomOrderRequest(this.orderCustomLabTestData).subscribe(
        (data) => {
          if (data.status == 1) {

            //this.requestResponse = data.data;
            let win = window.open("", "win", "fullscreen=yes"); // a window object
            win.document.open("text/html", "replace");
            win.document.write(data.data.html);
            win.document.close();

            let interval = setInterval(()=>{
              this.orderLabTestService.checkCustomOrderStatus(data.data.order_id).subscribe(
                (response)=>{
                  console.log(response);
                  this.requestResponse = response.data.message;
                  if (response.status == 1){ //transaction successful
                    //redirect to order confirmation page.
                    if (response.data.message == "success"){
                      //navigate to order confirmation page
                      this.requestResponse = "Navigate to Order Confirmation Page."
                      this._router.navigate(["/dashboard/order/"+data.data.order_id]);
                    }
                    this.transactionStarted = false;
                    this.isResponse = 1;
                    clearInterval(interval);
                  }
                });
            },1000);

          }
        }
      );
    }
  }
  processOfflineOrder() {
    if (this.orderCustomLabTestForm.form.valid) {
      
      this.orderCustomLabTestData.paymentMode = "offline";
      this.orderCustomLabTestData.testIds = this.testIds;

      this.orderCustomLabTestData.price = this.package_price;
      this.paymentMode = "offline";
      this.orderLabTestService.sendCustomOrderRequest(this.orderCustomLabTestData).subscribe(
        (data) => {
          console.log(data);
          if (data.status == 1) {
            this.success = "1";
            this._router.navigate(["/dashboard/order/"+data.data.order_id]);
            this.requestResponse = data.data;
            this.orderCustomLabTestForm.resetForm();
          }
        }
      );
    }
  }
  getAddress(){
    this.customerAddressService.getAll().subscribe(
      (data) => {
        this.addressData = data.data;
      }
    );
  }
  refreshAddress(e){
    if(e == 1){
      this.getAddress();
    }
  }
  onSubmit() {

  }
}
