import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Register } from '../_classes/register';
import AppConstants from '../config/AppConstants';
import { RegisterVerify } from '../_classes/register-verify';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private _http: HttpClient) { }
  verifyOTP(registerVerifyModel: RegisterVerify){
    let postData = new FormData();
    postData.append("OTP",""+registerVerifyModel.OTP);
    postData.append("username",localStorage.getItem("_TUSERNAME"));
    
    const httpOptions = {
      headers: new  HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_CUSTOMER_PHONE_VERIFY_URL_,postData,httpOptions);
  }
  registerUser(registerModel: Register){
    let postData = new FormData();
    postData.append("first_name",registerModel.firstName);
    postData.append("last_name",registerModel.lastName);
    postData.append("phone_number",registerModel.phoneNumber);
    postData.append("email_id",registerModel.emailId);
    postData.append("password",registerModel.password);
    const httpOptions = {
      headers: new  HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_CUSTOMER_REGISTER_URL,postData,httpOptions);
  }
}
