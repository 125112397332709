import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import AppConstants from 'src/app/config/AppConstants';
import { PhysiotherapyEnquiry } from 'src/app/_classes/physiotherapy-enquiry';
import { PhysiotherapyEnquiryService } from 'src/app/_services/physiotherapy-enquiry.service';
import { NgForm } from '@angular/forms';
import { LoginService } from 'src/app/_services/login.service';
import { ProfileService } from 'src/app/_services/profile.service';

@Component({
  selector: 'app-physiotherapy',
  templateUrl: './physiotherapy.component.html',
  styleUrls: ['./physiotherapy.component.css']
})
export class PhysiotherapyComponent implements OnInit {
  physiotherapyEnquiry = new PhysiotherapyEnquiry();
  submitSuccess= 0;
  isResponse = 1;
  constructor(
          private titleService: Title,
          private physiotherapyEnquiryService: PhysiotherapyEnquiryService,
          private LoginService: LoginService,
          private ProfileService: ProfileService) { }
  @ViewChild(NgForm) physiotherapyRequest: NgForm;
  onSubmit(){
    this.isResponse = 0;
    this.physiotherapyEnquiryService.submitEnquiry(this.physiotherapyEnquiry).subscribe(
      (data) =>{
        this.isResponse = 1;
        if (data.data == "success"){
        this.submitSuccess = 1;
        this.physiotherapyRequest.resetForm();
        }else{
          this.submitSuccess = 0;
        }
      } 
    )
  }
  ngOnInit() {
    this.titleService.setTitle("Physiotherapy  | "+AppConstants._COMPANY_NAME);
    if (this.LoginService.isAuthenticated()){
      
      this.ProfileService.getProfile(this.LoginService.getUid()).subscribe((data)=>{
        this.physiotherapyEnquiry.fullName = data.data.full_name;
        this.physiotherapyEnquiry.emailId = data.data.email_id;
        this.physiotherapyEnquiry.phoneNumber = data.data.phone_number;
      });
    }
  }

}
