import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import AppConstants from '../config/AppConstants';
import { DataService } from './data.service';
import { DashboardCount } from '../_interface/dashboard-count';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  uid: string;
  constructor(private _http: HttpClient,private _data: DataService ) { }
  getDashboardCount(){
    this._data.uid.subscribe(
      uid => this.uid = uid
    );
    
    return this._http.get<DashboardCount>(
      AppConstants._GET_CUSTOMER_DASHBOARD_COUNT_URL_+"&uid="+this.uid, 
      {
          responseType:"json",
      });
  }
}
