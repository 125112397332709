import { Component, OnInit, ViewChild } from '@angular/core';
import { NursingEnquiry } from 'src/app/_classes/nursing-enquiry';
import { NursingEnquiryService } from 'src/app/_services/nursing-enquiry.service';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import AppConstants from 'src/app/config/AppConstants';
import { ProfileService } from 'src/app/_services/profile.service';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-nursing',
  templateUrl: './nursing.component.html',
  styleUrls: ['./nursing.component.css']
})
export class NursingComponent implements OnInit {
  profileData: any;
  nursingEnquiry = new NursingEnquiry();
  submitSuccess= 0;
  isResponse = 1;

  constructor(private titleService: Title,private nursingEnquiryService: NursingEnquiryService, private ProfileService: ProfileService,private LoginService: LoginService) { }

  @ViewChild(NgForm) nursingRequest: NgForm;
  onSubmit(){
    this.isResponse = 0;
    this.nursingEnquiryService.submitEnquiry(this.nursingEnquiry).subscribe(
      (data) =>{
        this.isResponse = 1;
        if (data.data == "success"){
        this.submitSuccess = 1;
        this.nursingRequest.resetForm();
        }else{
          this.submitSuccess = 0;
        }
      } 
    )
  }
  ngOnInit() {
    this.titleService.setTitle("Nursing  | "+AppConstants._COMPANY_NAME);
    if (this.LoginService.isAuthenticated()){
      
      this.ProfileService.getProfile(this.LoginService.getUid()).subscribe((data)=>{
        this.profileData = data.data;
        this.nursingEnquiry.fullName = data.data.full_name;
        this.nursingEnquiry.emailId = data.data.email_id;
        this.nursingEnquiry.phoneNumber = data.data.phone_number;
      });
    }
  }

}
