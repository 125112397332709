import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-equipment-rent-requests',
  templateUrl: './medical-equipment-rent-requests.component.html',
  styleUrls: ['./medical-equipment-rent-requests.component.css']
})
export class MedicalEquipmentRentRequestsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
