import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicalEquipmentsService } from 'src/app/_services/medical-equipments.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OrderMedicalEquipmentService } from 'src/app/_services/order-medical-equipment.service';
import { OrderMedicalEquipmentBuy } from 'src/app/_classes/order-medical-equipment-buy';
import { CustomerAddressService } from 'src/app/_services/customer-address.service';
import AppConstants from 'src/app/config/AppConstants';

@Component({
  selector: 'app-order-medical-equipment-buy',
  templateUrl: './order-medical-equipment-buy.component.html',
  styleUrls: ['./order-medical-equipment-buy.component.css']
})
export class OrderMedicalEquipmentBuyComponent implements OnInit {
  public medicalEquipmentData: any;
  public id: string;
  public success: any;
  public addressData: any;
  public paymentMode: string;
  public transactionStarted: boolean;
  public requestResponse: string; 
  public orderMedicalEquipmentBuyData= new OrderMedicalEquipmentBuy();
  public weightUnit = AppConstants._WEIGHT_UNIT_;
  public FinalPrice: number;
  public isResponse = 1;
  constructor(
    private medicalEquipmentService: MedicalEquipmentsService,
    private route: ActivatedRoute,
    private orderMedicalEquipmentService: OrderMedicalEquipmentService,
    private _router: Router,
    private customerAddressService: CustomerAddressService) { }
  @ViewChild(NgForm) orderMedicalEquipmentBuyForm: NgForm; 
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.getAddress();
    this.medicalEquipmentService.getById(this.id).subscribe(
      (data) =>{ 
        this.FinalPrice = parseInt(data.data[0].eq_actual_price)+parseInt(data.data[0].eq_basic_shipping_charges);
        this.medicalEquipmentData = data.data[0];
        
      }
    )
    
    console.log(this.medicalEquipmentData);
  }
  onSubmit() {

  }
  handleDatePicker(e){
    console.log(this.orderMedicalEquipmentBuyForm);
  }
  processPaymentOrder() {
    
    if (this.orderMedicalEquipmentBuyForm.form.valid) {
      this.isResponse = 0;
      this.transactionStarted = true;
      this.orderMedicalEquipmentBuyData.paymentMode = "online";
      this.orderMedicalEquipmentBuyData.medicalEquipmentId = this.medicalEquipmentData.id;
      let price: number = (parseInt(this.medicalEquipmentData.eq_actual_price)+parseInt(this.medicalEquipmentData.eq_basic_shipping_charges));
      this.orderMedicalEquipmentBuyData.price = price;
      //console.log(price);
      this.paymentMode = "online";
      this.orderMedicalEquipmentService.sendOrderRequest(this.orderMedicalEquipmentBuyData).subscribe(
        (data) => {
          
          if (data.status == 1) {
            //this.requestResponse = data.data;
            let win = window.open("", "win", "fullscreen=yes"); // a window object
            win.document.open("text/html", "replace");
            win.document.write(data.data.html);
            win.document.close();

            let interval = setInterval(()=>{
              this.orderMedicalEquipmentService.checkOrderStatus(data.data.order_id).subscribe(
                (response)=>{
                  //console.log(response);
                  this.requestResponse = response.data.message;
                  if (response.status == 1){ //transaction successful
                    //redirect to order confirmation page.
                    if (response.data.message == "success"){
                      //navigate to order confirmation page
                      this.requestResponse = "Navigate to Order Confirmation Page."
                      this._router.navigate(["/dashboard/order/"+data.data.order_id]);
                    }
                    this.transactionStarted = false;
                    clearInterval(interval);
                    this.isResponse = 1;
                  }
                });
            },1000);

          }
        }
      );
    }
  }
  getAddress(){
    this.customerAddressService.getAll().subscribe(
      (data) => {
        this.addressData = data.data;
      }
    );
  }
  refreshAddress(e){
    if(e == 1){
      this.getAddress();
    }
  }
  processOfflineOrder() {
    this.isResponse =0;
    if (this.orderMedicalEquipmentBuyForm.form.valid) {
      this.orderMedicalEquipmentBuyData.paymentMode = "offline";
      this.orderMedicalEquipmentBuyData.medicalEquipmentId = this.medicalEquipmentData.id;
      this.orderMedicalEquipmentBuyData.price = this.medicalEquipmentData.eq_actual_price;
      this.paymentMode = "offline";
      this.orderMedicalEquipmentService.sendOrderRequest(this.orderMedicalEquipmentBuyData).subscribe(
        (data) => {
          this.isResponse =1;
          //console.log(data);
          if (data.status == 1) {
            this.success = "1";
            this._router.navigate(["/dashboard/order/"+data.data.order_id]);
            this.requestResponse = data.data;
            this.orderMedicalEquipmentBuyForm.resetForm();
          }
        }
      );
    }
  }
  

}
