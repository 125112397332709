import { Component, OnInit } from '@angular/core';
import { RadiologyEnquiryService } from 'src/app/_services/radiology-enquiry.service';

@Component({
  selector: 'app-radiology-requests',
  templateUrl: './radiology-requests.component.html',
  styleUrls: ['./radiology-requests.component.css']
})
export class RadiologyRequestsComponent implements OnInit {

  public radiologyData: any;
  public isRadiology: number;
  constructor(private radiologyEnquiryService: RadiologyEnquiryService) { }


  ngOnInit() {
    this.radiologyEnquiryService.getCustomerRequests().subscribe((data)=>{
      if (data.status){
        this.radiologyData = data.data;
        this.isRadiology = 1;
      }else{
        this.isRadiology = 0;
        this.radiologyData = 0;
      }
    });
  }

}
