import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FeedbackForm } from '../_classes/feedback-form';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class FeedbackFormService {

  constructor(private _http: HttpClient) { }
  createFeedback(feedbackForm: FeedbackForm){
    let postData = new FormData();
    postData.append("full_name",feedbackForm.fullName);
    postData.append("phone_number",""+feedbackForm.phoneNumber);
    postData.append("email_id",feedbackForm.emailId);
    postData.append("rating",feedbackForm.rating);
    postData.append("message",feedbackForm.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_FEEDBACK_URL_,postData,httpOptions);
  }
}
