import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders } from "@angular/common/http";
import AppConstants from '../config/AppConstants';
import { NursingEnquiry } from '../_classes/nursing-enquiry';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class NursingEnquiryService {
  _url = AppConstants._POST_NURSING_ENQUIRY_URL_;
  uid: string;
  constructor(private _http: HttpClient,private _data: DataService) {
    this._data.uid.subscribe(
      uid => this.uid = uid
    );
   }
  getCustomerRequests(){
    return this._http.get<any>(AppConstants._GET_CUSTOMER_NURSING_REQUEST_URL_+"&uid="+this.uid);
  }
  submitEnquiry(nursingEnquiry: NursingEnquiry){
    let postData = new FormData();
    postData.append("full_name",nursingEnquiry.fullName);
    postData.append("phone_number",""+nursingEnquiry.phoneNumber);
    postData.append("email_id",nursingEnquiry.emailId);
    postData.append("number_of_days",""+nursingEnquiry.noOfDays);
    postData.append("address",nursingEnquiry.address);
    postData.append("message",nursingEnquiry.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_NURSING_ENQUIRY_URL_,postData,httpOptions);
  }

}
