import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PhysiotherapyEnquiry } from '../_classes/physiotherapy-enquiry';
import AppConstants from '../config/AppConstants';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class PhysiotherapyEnquiryService {
  public uid: string;
  constructor(private _http: HttpClient, private _data: DataService) {
    this._data.uid.subscribe(
      uid => this.uid = uid
    );  
  }
  getCustomerRequests(){
    return this._http.get<any>(AppConstants._GET_CUSTOMER_PHYSIOTHERAPY_REQUEST_URL_+"&uid="+this.uid);
  }
  submitEnquiry(physiotherapyEnquiry: PhysiotherapyEnquiry){
    let postData = new FormData();
    postData.append("full_name",physiotherapyEnquiry.fullName);
    postData.append("phone_number",""+physiotherapyEnquiry.phoneNumber);
    postData.append("email_id",physiotherapyEnquiry.emailId);
    postData.append("address",physiotherapyEnquiry.address);
    postData.append("message",physiotherapyEnquiry.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_PHYSIOTHERAPY_ENQUIRY_URL_,postData,httpOptions);
  }
}
