import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NursingComponent } from './pages/nursing/nursing.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';
import { UploadPrescriptionComponent } from './pages/upload-prescription/upload-prescription.component';
import { PhysiotherapyComponent } from './pages/physiotherapy/physiotherapy.component';
import { RadiologyComponent } from './pages/radiology/radiology.component';
import { MedicalEquipmentsComponent } from './pages/medical-equipments/medical-equipments.component';
import { BloodTestComponent } from './pages/blood-test/blood-test.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { LogoutComponent } from './logout/logout.component';
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { MedicalInsuranceComponent } from './pages/medical-insurance/medical-insurance.component';
import { RegisterComponent } from './pages/register/register.component';
import { VerifyComponent } from './pages/register/verify/verify.component';
import { EquipmentByIdComponent } from './pages/medical-equipments/equipment-by-id/equipment-by-id.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { MedicalEquipmentTermsComponent } from './pages/medical-equipment-terms/medical-equipment-terms.component';
import { NursingRequestsComponent } from './dashboard/nursing-requests/nursing-requests.component';
import { RadiologyRequestsComponent } from './dashboard/radiology-requests/radiology-requests.component';
import { PhysiotherapyRequestsComponent } from './dashboard/physiotherapy-requests/physiotherapy-requests.component';
import { PrescriptionsRequestsComponent } from './dashboard/prescriptions-requests/prescriptions-requests.component';
import { MedicalEquipmentRentRequestsComponent } from './dashboard/medical-equipment-rent-requests/medical-equipment-rent-requests.component';
import { MedicalEquipmentPurchaseRecordsComponent } from './dashboard/medical-equipment-purchase-records/medical-equipment-purchase-records.component';
import { UserPasswordComponent } from './dashboard/user-password/user-password.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { OrderLabTestsComponent } from './pages/order-lab-tests/order-lab-tests.component';
import { CustomerAddressComponent } from './dashboard/customer-address/customer-address.component';
import { CustomerAddressCreateComponent } from './dashboard/customer-address-create/customer-address-create.component';
import { TrackOrderComponent } from './dashboard/track-order/track-order.component';
import { BloodTestsComponent } from './dashboard/blood-tests/blood-tests.component';
import { AllPackagesComponent } from './pages/all-packages/all-packages.component';
import { OrderCustomLabTestsComponent } from './pages/order-custom-lab-tests/order-custom-lab-tests.component';
import { OrderMedicalEquipmentBuyComponent } from './pages/order-medical-equipment-buy/order-medical-equipment-buy.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: 'nursing', component: NursingComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'services', component: ServicesComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent},
  { path: "terms-of-service", component: TermsOfServiceComponent},
  { path: "medical-equipment-policy", component: MedicalEquipmentTermsComponent},
  { path: 'upload-prescription', component: UploadPrescriptionComponent },
  { path: 'physiotherapy', component: PhysiotherapyComponent},
  { path: 'radiology', component: RadiologyComponent },
  { path: 'medical-equipments', component: MedicalEquipmentsComponent },
  { path: "all-packages", component: AllPackagesComponent},
  { path: 'medical-equipment/:id', component: EquipmentByIdComponent},
  { path: 'order/test-package/:id', component: OrderLabTestsComponent},
  { path: "medical-equipment/buy/:id", component: OrderMedicalEquipmentBuyComponent },
  { path: "medical-insurance", component: MedicalInsuranceComponent},
  { path: 'blood-test', component: BloodTestComponent },
  { path: "login",component: LoginComponent},
  { path: "login/:redirect", component: LoginComponent},
  { path: "partners", component: PartnersComponent},
  { path: "register", component:RegisterComponent},
  { path: "register/:redirect", component: RegisterComponent},
  { path: "account-verification", component: VerifyComponent},
  { path: "order/custom-test-package", component: OrderCustomLabTestsComponent},
  { path: 'dashboard', component: DashboardComponent ,children:[
    { path: "", component: DashboardHomeComponent},
    { path: "password", component: UserPasswordComponent},
    { path: "profile", component: ProfileComponent},
    { path: "addresses", component: CustomerAddressComponent},
    { path: "address/add",component: CustomerAddressCreateComponent},
    { path: "address/edit/:id", component: CustomerAddressCreateComponent},
    { path: "order/:id", component: TrackOrderComponent},
    { path: "blood-tests", component: BloodTestsComponent},
    { path: "nursing", component: NursingRequestsComponent},
    { path: "radiology", component: RadiologyRequestsComponent},
    { path: "physiotherapy", component: PhysiotherapyRequestsComponent},
    { path: "prescriptions", component: PrescriptionsRequestsComponent},
    { path: "medical-equipment/rent", component: MedicalEquipmentRentRequestsComponent},
    { path: "medical-equipment/purchased", component: MedicalEquipmentPurchaseRecordsComponent}
  ], canActivate: [AuthGuardService]},
  { path: "logout", component: LogoutComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
