import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class FeaturedPackagesService {

  constructor(private _http: HttpClient) { }
  getData(){
    return this._http.get<any>(AppConstants._GET_FEATURED_PACKAGES_URL_);
  }
  getById(id: string){
    return this._http.get<any>(AppConstants._GET_FEATURED_PACKAGES_URL_+"&id="+id);
  }
}
