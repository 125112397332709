import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContactForm } from '../_classes/contact-form';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {
  constructor(private _http: HttpClient) { }
  createEnquiry(contactForm: ContactForm){
    let postData = new FormData();
    postData.append("full_name",contactForm.fullName);
    postData.append("phone_number",""+contactForm.phoneNumber);
    postData.append("email_id",contactForm.emailId);
    postData.append("message",contactForm.message);
    
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_CONTACT_US_URL_,postData,httpOptions);
  }
}
