import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from '../_classes/login';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _http: HttpClient) { }
  checkUser(loginModel: Login){
    let postData = new FormData();
    postData.append("username",loginModel.username);
    
    const httpOptions = {
      headers: new  HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_CHECK_USERNAME_URL_,postData,httpOptions);
  }
  resendOTP(loginModel: Login){
    let postData = new FormData();
    postData.append("username",loginModel.username);
    
    const httpOptions = {
      headers: new  HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_AUTH_RESEND_OTP_URL_,postData,httpOptions);
  }
  customerLogin(loginModel: Login){
    let postData = new FormData();
    postData.append("username",loginModel.username);
    if (loginModel.OTP){
      postData.append("OTP",""+loginModel.OTP);
    }else{
      postData.append('password',loginModel.password);
    }
    const httpOptions = {
      headers: new  HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_AUTH_CUSTOMER_URL_,postData,httpOptions);
  }
  getUsername(){
    return localStorage.getItem("_USERNAME");
  }
  getFirstName(){
    return localStorage.getItem("_FIRST_NAME");
  }
  setFirstName(name: string){
    localStorage.setItem("_FIRST_NAME",name);
    return true;
  }
  getUid(){
    return localStorage.getItem("_UID");
  }
  isAuthenticated(){
    if (localStorage.getItem("_UID")){
      return true;
    }else{
      return false;
    }
  }
}
