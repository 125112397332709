import { Component, OnInit } from '@angular/core';
import { CitiesService } from 'src/app/_services/cities.service';

@Component({
  selector: 'app-city-selection',
  templateUrl: './city-selection.component.html',
  styleUrls: ['./city-selection.component.css']
})
export class CitySelectionComponent implements OnInit {
  public citySelected: any;
  public cities: any;
  constructor(private CitiesService: CitiesService) { }

  ngOnInit() {
    this.citySelected = "Mumbai";
    this.CitiesService.getCities().subscribe(
      (data)=>{
        this.cities = data.data;
      }
    );
  }
  selectCity(e: string){
    console.log(e);
  }

}
