import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CustomerAddress } from 'src/app/_classes/customer-address';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerAddressService } from 'src/app/_services/customer-address.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-address-modal',
  templateUrl: './create-address-modal.component.html',
  styleUrls: ['./create-address-modal.component.css']
})
export class CreateAddressModalComponent implements OnInit {
  customerAddress = new CustomerAddress();
  submitSuccess = 0;
  _modalDisplay: string;
  responseMessage = "";
  addressId: string;
  presentData: any;
  @Input() redirect = "";
  @Output() response = new EventEmitter();
  constructor(private _router: Router,
    private customerAddressService: CustomerAddressService,
    private _activeRoute: ActivatedRoute) { }

  @ViewChild(NgForm) customerAddressForm: NgForm;
  onSubmit(){
    if (this.addressId){
      this.customerAddressService.updateAddress(this.customerAddress,this.addressId).subscribe(
        (data) =>{
          console.log(data);
          if (data.status == 1){
            this.submitSuccess = 1;
            if (this.redirect !== "false"){
              this._router.navigate(['dashboard/addresses']);
            }else{
              this._modalDisplay = "none";
              this.response.next(1)
            }
            
            }else{
              this.submitSuccess = 0;
              this.responseMessage = data.data;
            }
        }
      );
    }else{
    this.customerAddressService.createAddress(this.customerAddress).subscribe(
      (data) =>{
        if (data.status == 1){
        this.submitSuccess = 1;
        this.customerAddressForm.resetForm();
        if (this.redirect !== "false"){
          this._router.navigate(['dashboard/addresses']);
        }else{
          //this._modalDisplay = "none";
          $('.modal-backdrop,#myAddressModal').removeClass('show');

          this.response.next(1)
        }
        
        }else{
          this.submitSuccess = 0;
          this.responseMessage = data.data;
        }
      } 
    )
    }
  }
  closeModal(){
    $('.modal-backdrop,#myAddressModal').removeClass('show');
  }
  ngOnInit() {
    this.addressId = this._activeRoute.snapshot.paramMap.get("address_id");
    if (this.addressId){
      //load the form with values
      this.presentData = this.customerAddressService.getById(this.addressId).subscribe(
        (data) => {
          this.customerAddress.alias = data.data[0].alias;
          this.customerAddress.addressLine1 = data.data[0].address_1;
          this.customerAddress.addressLine2 = data.data[0].address_2;
          this.customerAddress.city         = data.data[0].city;
          this.customerAddress.state        = data.data[0].state;
          this.customerAddress.pinCode      = data.data[0].pin_code;
          this.customerAddress.country      = data.data[0].country;
          this.customerAddress.landMark     = data.data[0].landmark;
        }
      )
    }
  }

}
