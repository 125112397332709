import { Component, OnInit, ViewChild } from '@angular/core';
import { GetCallBackForm } from '../_classes/get-call-back-form';
import { GetCallBackFormService } from '../_services/get-call-back-form.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-get-call-back',
  templateUrl: './get-call-back.component.html',
  styleUrls: ['./get-call-back.component.css']
})
export class GetCallBackComponent implements OnInit {
  getCallBackFormData = new GetCallBackForm();
  submitSuccess= 0;
  isResponse = 1;
  constructor(private getCallBackFormService: GetCallBackFormService) { }
  @ViewChild(NgForm) getCallBackForm: NgForm;
  ngOnInit() {
  }
  onSubmit(){
    this.isResponse = 0;
    this.getCallBackFormService.createEnquiry(this.getCallBackFormData).subscribe(
      (data) =>{
        if (data.status == "1"){
        this.submitSuccess = 1;
        this.getCallBackForm.resetForm();
        }else{
          this.submitSuccess = 0;
        }
        this.isResponse = 1;
      } 
    )
  }
  resetFlags(){
    this.submitSuccess = 0;
  }
}
