import { Component, OnInit } from '@angular/core';
import { NursingEnquiryService } from 'src/app/_services/nursing-enquiry.service';

@Component({
  selector: 'app-nursing-requests',
  templateUrl: './nursing-requests.component.html',
  styleUrls: ['./nursing-requests.component.css']
})
export class NursingRequestsComponent implements OnInit {
  public nursingData: any;
  public isNursing: number;
  constructor(private NursingService: NursingEnquiryService) { }

  ngOnInit() {
    this.NursingService.getCustomerRequests().subscribe(
      (data)=>{
        if (data.status){
          this.nursingData = data.data;
          this.isNursing = 1;
        }else{
          this.isNursing = 0;
          this.nursingData = 0;
        }
      }
    )
  }

}
