export class NursingEnquiry {
    public fullName: string;
    public emailId: string;
    public phoneNumber: number;
    public noOfDays: number;
    public address: string;
    public message: string;

    constructor(){}
}
