import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prescriptions-requests',
  templateUrl: './prescriptions-requests.component.html',
  styleUrls: ['./prescriptions-requests.component.css']
})
export class PrescriptionsRequestsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
