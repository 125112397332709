import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import AppConstants from '../config/AppConstants';
import { BloodTestService } from '../_services/blood-test.service';
import { NgForm } from '@angular/forms';
import { SearchForm } from '../_classes/search-form';
import { DataService } from '../_services/data.service';
import { LoginService } from '../_services/login.service';
import { Router } from '@angular/router';
interface testData{
  id: Number;
  test_name: String;
}
interface testStore{
  status: Number;
  data: testData[];
}
@Component({
  selector: 'app-blood-test-grid',
  templateUrl: './blood-test-grid.component.html',
  styleUrls: ['./blood-test-grid.component.css']
})
export class BloodTestGridComponent implements OnInit {
  public tests: any; 
  public isLogin: number;
  public selectTestItem: any;
  public isResponse: number = 0;
  public searchFormData = new SearchForm();
  public CURRENCY = AppConstants._CURRENCY_SYS_;
  public query: string;
  constructor(private bloodTestService: BloodTestService,private data: DataService,
    private loginService: LoginService,private _router: Router) { }
  @ViewChild(NgForm) searchForm: NgForm;
  ngOnInit() {
    this.data.isLogin.subscribe(
      isLogin => this.isLogin = isLogin
    );
    if (this.loginService.isAuthenticated()){
      this.isLogin = 1;
    }else{
      this.isLogin = 0;
    }
     //set meta data
     this.bloodTestService.getTests().subscribe((res: testStore)=>{
      //console.log(res);
      this.isResponse=1;
      this.tests = res.data;
    });
  }
  filterSelectedTests(test:any){
    return this.tests.filter(x => {
      return x.selected == 1;
    });
  }
  submitCustomTests(){
    if (this.filterSelectedTests(this.tests).length >= 1){
    this.data.changeSelectedTests(this.filterSelectedTests(this.tests));
      if (this.isLogin == 1){
        this._router.navigate(['/order/custom-test-package']);
      }else{
        this._router.navigate(['/login/order_custom-test-package']); 
      }
    }
  }
  startSearching(q:string){
    this.query = this.searchFormData.query;
    console.log(this.query);
  }
  filterSearch(tests:any){
    if (this.query){
      return tests.filter(x => {
        return x.test_name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0;
      });
    }else{
      return this.tests;
    }
  }
  selectTest(test: any){
    this.selectTestItem = test;
    this.tests.forEach(element => {
      if (element.id === this.selectTestItem.id){
        element.selected = 1;
      }
    });
  }
  removeSelectedItem(test: any){
    this.selectTestItem = test;
    this.tests.forEach(element => {
      if (element.id === this.selectTestItem.id){
        element.selected = 0;
      }
    });
  }
}
