import { Component, OnInit } from '@angular/core';
import { PartnersService } from 'src/app/_services/partners.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  public partnersData: any;
  public isResponse = 0;
  constructor(private partnerService: PartnersService) { }

  ngOnInit() {
    this.partnerService.getAll().subscribe(
      (data)=> {
        this.isResponse = 1;
        this.partnersData = data.data;
      }
    );
  }

}
