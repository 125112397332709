import { Component, OnInit } from '@angular/core';
import { PhysiotherapyEnquiryService } from 'src/app/_services/physiotherapy-enquiry.service';

@Component({
  selector: 'app-physiotherapy-requests',
  templateUrl: './physiotherapy-requests.component.html',
  styleUrls: ['./physiotherapy-requests.component.css']
})
export class PhysiotherapyRequestsComponent implements OnInit {
  public physiotherapyData: any;
  public isPhysiotherapy: number;
  constructor(private physiotherapyRequestService: PhysiotherapyEnquiryService) { }

  ngOnInit() {
    this.physiotherapyRequestService.getCustomerRequests().subscribe((data)=>{
      if (data.status){
        this.physiotherapyData = data.data;
        this.isPhysiotherapy = 1;
      }else{
        this.isPhysiotherapy = 0;
        this.physiotherapyData = 0;
      }
    })
  }

}
