import { Component, OnInit, setTestabilityGetter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import AppConstants from 'src/app/config/AppConstants';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private titleService: Title) { }
  
  ngOnInit() {
    this.titleService.setTitle("About Us | "+AppConstants._COMPANY_NAME);
  }

}
