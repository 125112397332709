import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProfileForm } from '../_classes/profile-form';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient) { }
  getProfile(uid: string){
    return this._http.get<any>(AppConstants._GET_PROFILE_URL_+"&uid="+uid);    
  }
  updateProfile(profileData: ProfileForm){
    let postData = new FormData();
    postData.append("full_name",profileData.full_name);
    postData.append("email_id",profileData.email_id);
    postData.append("uid",profileData.uid);
    postData.append("phone_number",profileData.phone_number);
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_PROFILE_UPDATE_URL_,postData,httpOptions);
  }
  updateProfilePassword(profileData: ProfileForm){
    let postData = new FormData();
    postData.append("uid",profileData.uid);
    postData.append("password",profileData.password);
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded'
      })
    };
    return this._http.post<any>(AppConstants._POST_PASSWORD_UPDATE_URL_,postData,httpOptions);
  }
}
