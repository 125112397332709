import { Injectable } from '@angular/core';
import AppConstants from '../config/AppConstants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BloodTestService {

  constructor(private _http: HttpClient) { }
  getTests(){
    return this._http.get(
      AppConstants._BLOOD_TESTS_URL_, 
      {
          responseType:"json",
      });
  }
}
