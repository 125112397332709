import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NursingComponent } from './pages/nursing/nursing.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ServiceGridComponent } from './service-grid/service-grid.component';
import { ServicesComponent } from './pages/services/services.component';
import { RadiologyComponent } from './pages/radiology/radiology.component';
import { PhysiotherapyComponent } from './pages/physiotherapy/physiotherapy.component';
import { UploadPrescriptionComponent } from './pages/upload-prescription/upload-prescription.component';
import { MedicalEquipmentsComponent } from './pages/medical-equipments/medical-equipments.component';
import { HttpClientModule } from '@angular/common/http';
import { BloodTestComponent } from './pages/blood-test/blood-test.component';
import { BloodTestGridComponent } from './blood-test-grid/blood-test-grid.component';
import { GetCallBackComponent } from './get-call-back/get-call-back.component';
import { DefaultTopBarComponent } from './default-top-bar/default-top-bar.component';
import { UserTopBarComponent } from './user-top-bar/user-top-bar.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { LogoutComponent } from './logout/logout.component';
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { MedicalInsuranceComponent } from './pages/medical-insurance/medical-insurance.component';
import { RegisterComponent } from './pages/register/register.component';
import { VerifyComponent } from './pages/register/verify/verify.component';
import { EquipmentByIdComponent } from './pages/medical-equipments/equipment-by-id/equipment-by-id.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { MedicalEquipmentTermsComponent } from './pages/medical-equipment-terms/medical-equipment-terms.component';
import { FeedbackFormComponent } from './_forms/feedback-form/feedback-form.component';
import { NursingRequestsComponent } from './dashboard/nursing-requests/nursing-requests.component';
import { RadiologyRequestsComponent } from './dashboard/radiology-requests/radiology-requests.component';
import { PhysiotherapyRequestsComponent } from './dashboard/physiotherapy-requests/physiotherapy-requests.component';
import { PrescriptionsRequestsComponent } from './dashboard/prescriptions-requests/prescriptions-requests.component';
import { MedicalEquipmentRentRequestsComponent } from './dashboard/medical-equipment-rent-requests/medical-equipment-rent-requests.component';
import { MedicalEquipmentPurchaseRecordsComponent } from './dashboard/medical-equipment-purchase-records/medical-equipment-purchase-records.component';
import { UserPasswordComponent } from './dashboard/user-password/user-password.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { OrderLabTestsComponent } from './pages/order-lab-tests/order-lab-tests.component';
import { CustomerAddressComponent } from './dashboard/customer-address/customer-address.component';
import { CustomerAddressCreateComponent } from './dashboard/customer-address-create/customer-address-create.component';
import { CustomerAddressEditComponent } from './dashboard/customer-address-edit/customer-address-edit.component';
import { SafePipe } from './safe.pipe';
import { TrackOrderComponent } from './dashboard/track-order/track-order.component';
import { BloodTestsComponent } from './dashboard/blood-tests/blood-tests.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { CreateAddressModalComponent } from './_forms/create-address-modal/create-address-modal.component';
import { DateTimePickerComponent } from './_components/date-time-picker/date-time-picker.component';
import { AllPackagesComponent } from './pages/all-packages/all-packages.component';
import { CitySelectionComponent } from './_components/city-selection/city-selection.component';
import { CitySelectionPopupComponent } from './_components/city-selection-popup/city-selection-popup.component';
import { OrderCustomLabTestsComponent } from './pages/order-custom-lab-tests/order-custom-lab-tests.component';
import { OrderMedicalEquipmentBuyComponent } from './pages/order-medical-equipment-buy/order-medical-equipment-buy.component';
import { AjaxLoaderComponent } from './_components/ajax-loader/ajax-loader.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeSliderComponent,
    ContactUsComponent,
    AboutUsComponent,
    NursingComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ServiceGridComponent,
    ServicesComponent,
    RadiologyComponent,
    PhysiotherapyComponent,
    UploadPrescriptionComponent,
    MedicalEquipmentsComponent,
    BloodTestComponent,
    BloodTestGridComponent,
    GetCallBackComponent,
    DefaultTopBarComponent,
    UserTopBarComponent,
    LoginComponent,
    DashboardComponent,
    ProfileComponent,
    SettingsComponent,
    LogoutComponent,
    DashboardHomeComponent,
    MedicalInsuranceComponent,
    RegisterComponent,
    VerifyComponent,
    EquipmentByIdComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    MedicalEquipmentTermsComponent,
    FeedbackFormComponent,
    NursingRequestsComponent,
    RadiologyRequestsComponent,
    PhysiotherapyRequestsComponent,
    PrescriptionsRequestsComponent,
    MedicalEquipmentRentRequestsComponent,
    MedicalEquipmentPurchaseRecordsComponent,
    UserPasswordComponent,
    PartnersComponent,
    OrderLabTestsComponent,
    CustomerAddressComponent,
    CustomerAddressCreateComponent,
    CustomerAddressEditComponent,
    SafePipe,
    TrackOrderComponent,
    BloodTestsComponent,
    CreateAddressModalComponent,
    DateTimePickerComponent,
    AllPackagesComponent,
    CitySelectionComponent,
    CitySelectionPopupComponent,
    OrderCustomLabTestsComponent,
    OrderMedicalEquipmentBuyComponent,
    AjaxLoaderComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DlDateTimeDateModule,  // <--- Determines the data type of the model
    DlDateTimePickerModule,
  ],
  providers: [
      {provide: LocationStrategy, useClass: PathLocationStrategy},AuthGuardService],
  bootstrap: [AppComponent]
})

export class AppModule { }
