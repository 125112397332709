import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import AppConstants from '../config/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private _http: HttpClient) { }
  getFAQs(){
    return this._http.get<any>(AppConstants._GET_FAQ_URL_);
  }
}
