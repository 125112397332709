import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderLabTestService } from 'src/app/_services/order-lab-test.service';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.css']
})
export class TrackOrderComponent implements OnInit {
  orderId: string;
  orderDetails: any;
  constructor(
    private _activeRoute: ActivatedRoute, 
    private orderLabTestService: OrderLabTestService
    ) { }
  public greaterThanEqualTo(x: number, y: number) {
    return x >= y;
  }
  ngOnInit() {
    this.orderId = this._activeRoute.snapshot.paramMap.get("id");
    this.orderLabTestService.getOrderById(this.orderId).subscribe(
      (data) => {
        this.orderDetails = data.data;

        console.log(this.orderDetails);
      }
    );
  }

}
