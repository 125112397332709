import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ajax-loader',
  templateUrl: './ajax-loader.component.html',
  styleUrls: ['./ajax-loader.component.css']
})
export class AjaxLoaderComponent implements OnInit {
  @Input() isResponse: number; 
  @Input() loadingText: string;
  constructor() { }

  ngOnInit() {
  }

}
