import { Component, OnInit, ViewChild } from '@angular/core';
import { RadiologyEnquiry } from 'src/app/_classes/radiology-enquiry';
import { RadiologyEnquiryService } from 'src/app/_services/radiology-enquiry.service';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import AppConstants from 'src/app/config/AppConstants';
import { LoginService } from 'src/app/_services/login.service';
import { ProfileService } from 'src/app/_services/profile.service';

@Component({
  selector: 'app-radiology',
  templateUrl: './radiology.component.html',
  styleUrls: ['./radiology.component.css']
})
export class RadiologyComponent implements OnInit {

  radiologyEnquiry = new RadiologyEnquiry();
  submitSuccess= 0;
  isResponse = 1;
  constructor(private titleService: Title,
              private radiologyEnquiryService: RadiologyEnquiryService,
              private LoginService: LoginService,
              private ProfileService: ProfileService
              ) { }
  
  @ViewChild(NgForm) radiologyRequest: NgForm;
  onSubmit(){
    this.isResponse =0;
    this.radiologyEnquiryService.submitEnquiry(this.radiologyEnquiry).subscribe(
      (data) =>{
        this.isResponse = 1;
        if (data.data == "success"){
        this.submitSuccess = 1;
        this.radiologyRequest.resetForm();
        }else{
          this.submitSuccess = 0;
        }
      } 
    )
  }
  ngOnInit() {
    this.titleService.setTitle("Radiology  | "+AppConstants._COMPANY_NAME);
    if (this.LoginService.isAuthenticated()){
      
      this.ProfileService.getProfile(this.LoginService.getUid()).subscribe((data)=>{
        this.radiologyEnquiry.fullName = data.data.full_name;
        this.radiologyEnquiry.emailId = data.data.email_id;
        this.radiologyEnquiry.phoneNumber = data.data.phone_number;
      });
    }
  }

}
