import { Component, OnInit, ViewChild } from '@angular/core';
import { PhysiotherapyEnquiry } from 'src/app/_classes/physiotherapy-enquiry';
import { Title } from '@angular/platform-browser';
import { PhysiotherapyEnquiryService } from 'src/app/_services/physiotherapy-enquiry.service';
import { NgForm } from '@angular/forms';
import AppConstants from 'src/app/config/AppConstants';
import { LoginService } from 'src/app/_services/login.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { MedicalInsuranceData } from 'src/app/_classes/medical-insurance-data';
import { MedicalInsuranceService } from 'src/app/_services/medical-insurance.service';

@Component({
  selector: 'app-medical-insurance',
  templateUrl: './medical-insurance.component.html',
  styleUrls: ['./medical-insurance.component.css']
})
export class MedicalInsuranceComponent implements OnInit {
  medicalInsuranceData = new MedicalInsuranceData();
  submitSuccess= 0;
  isResponse = 1;
  constructor(
        private titleService: Title,
        private LoginService: LoginService,
        private ProfileService: ProfileService,
        private MedicalInsuranceService: MedicalInsuranceService) { }
  @ViewChild(NgForm) medicalInsuranceForm: NgForm;
  onSubmit(){
    this.isResponse = 0;
    this.MedicalInsuranceService.submitEnquiry(this.medicalInsuranceData).subscribe(
      (data) =>{
        this.isResponse = 1;
        if (data.data == "success"){
        this.submitSuccess = 1;
        this.medicalInsuranceForm.resetForm();
        }else{
          this.submitSuccess = 0;
        }
      } 
    )
  }
  ngOnInit() {
    this.titleService.setTitle("Health Insurance  | "+AppConstants._COMPANY_NAME);
    if (this.LoginService.isAuthenticated()){
      
      this.ProfileService.getProfile(this.LoginService.getUid()).subscribe((data)=>{
        this.medicalInsuranceData.fullName = data.data.full_name;
        this.medicalInsuranceData.emailId = data.data.email_id;
        this.medicalInsuranceData.phoneNumber = data.data.phone_number;
      });
    }
  }

}
