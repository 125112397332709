import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import AppConstants from '../config/AppConstants';
import { PrescriptionForm } from '../_classes/prescription-form';
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class UploadPrescriptionService {
  public uid: string;
  constructor(private _http: HttpClient, private _data: DataService) {
    this._data.uid.subscribe(
      uid => this.uid = uid
    );  
  }
  getCustomerRequests(){
    return this._http.get<any>(AppConstants._GET_CUSTOMER_PRESCRIPTIONS_REQUEST_URL_+"&uid="+this.uid);
  }
  uploadData(prescriptionData: PrescriptionForm){
    let postData = new FormData();
    postData.append("full_name",prescriptionData.fullName);
    postData.append("phone_number",""+prescriptionData.phoneNumber);
    postData.append("email_id",prescriptionData.emailId);
    postData.append("address",prescriptionData.address);
    postData.append("message",prescriptionData.message);
    postData.append("prescription",prescriptionData.prescription);
    
    const httpOptions = {
      headers: new HttpHeaders({
       // 'Content-Type': "application/png"
      })
    };
    return this._http.post<any>(AppConstants._POST_PRESCRIPTION_URL_,postData,httpOptions);
  }
}
