import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private isLoginSource = new BehaviorSubject<number>(0);
  isLogin = this.isLoginSource.asObservable();
  
  private uidSource = new BehaviorSubject<string>(localStorage.getItem("_UID"));
  uid = this.uidSource.asObservable();

  private firstNameSource = new BehaviorSubject<string>(localStorage.getItem("_FIRST_NAME"));
  firstName = this.firstNameSource.asObservable();

  private lastNameSource = new BehaviorSubject<string>(localStorage.getItem("_LAST_NAME"));
  lastName = this.lastNameSource.asObservable();

  private selectedTestsSource = new BehaviorSubject<any>(0);
  selectedTests = this.selectedTestsSource.asObservable();
  constructor() { }
  
  changeLoginStatus(status: number){
    this.isLoginSource.next(status);
    this.uidSource.next(localStorage.getItem("_UID"));
  }
  changeSelectedTests(tests: any){
    this.selectedTestsSource.next(tests);
  }
  changeFirstName(name: string){
    this.firstNameSource.next(name);
  }
  changeLastName(name: string){
    this.lastNameSource.next(name);
  }
}
